let wasm;

const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

let heap_next = heap.length;

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

function getObject(idx) { return heap[idx]; }

function dropObject(idx) {
    if (idx < 132) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

const cachedTextDecoder = (typeof TextDecoder !== 'undefined' ? new TextDecoder('utf-8', { ignoreBOM: true, fatal: true }) : { decode: () => { throw Error('TextDecoder not available') } } );

if (typeof TextDecoder !== 'undefined') { cachedTextDecoder.decode(); };

let cachedUint8Memory0 = null;

function getUint8Memory0() {
    if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

let WASM_VECTOR_LEN = 0;

const cachedTextEncoder = (typeof TextEncoder !== 'undefined' ? new TextEncoder('utf-8') : { encode: () => { throw Error('TextEncoder not available') } } );

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

function isLikeNone(x) {
    return x === undefined || x === null;
}

let cachedInt32Memory0 = null;

function getInt32Memory0() {
    if (cachedInt32Memory0 === null || cachedInt32Memory0.byteLength === 0) {
        cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachedInt32Memory0;
}

let cachedUint32Memory0 = null;

function getUint32Memory0() {
    if (cachedUint32Memory0 === null || cachedUint32Memory0.byteLength === 0) {
        cachedUint32Memory0 = new Uint32Array(wasm.memory.buffer);
    }
    return cachedUint32Memory0;
}

function getArrayJsValueFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    const mem = getUint32Memory0();
    const slice = mem.subarray(ptr / 4, ptr / 4 + len);
    const result = [];
    for (let i = 0; i < slice.length; i++) {
        result.push(takeObject(slice[i]));
    }
    return result;
}

let stack_pointer = 128;

function addBorrowedObject(obj) {
    if (stack_pointer == 1) throw new Error('out of js stack');
    heap[--stack_pointer] = obj;
    return stack_pointer;
}
/**
* Escapes all regular expression meta characters in `text`.
*
* The string returned may be safely used as a literal in a regular
* expression.
* @param {string} text
* @returns {string}
*/
export function escape(text) {
    let deferred2_0;
    let deferred2_1;
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.escape(retptr, ptr0, len0);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        deferred2_0 = r0;
        deferred2_1 = r1;
        return getStringFromWasm0(r0, r1);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
        wasm.__wbindgen_free(deferred2_0, deferred2_1, 1);
    }
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        wasm.__wbindgen_exn_store(addHeapObject(e));
    }
}

const RRegexFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rregex_free(ptr >>> 0));
/**
* A compiled regular expression for matching Unicode strings.
*
* A `RRegex` can be used to search haystacks, split haystacks into substrings
* or replace substrings in a haystack with a different substring. All
* searching is done with an implicit `(?s:.)*?` at the beginning and end of
* an pattern. To force an expression to match the whole string (or a prefix
* or a suffix), you must use an anchor like `^` or `$` (or `\A` and `\z`).
*
* While this library will handle Unicode strings (whether in the regular
* expression or in the haystack), all positions returned are **byte
* offsets**. Every byte offset is guaranteed to be at a Unicode code point
* boundary. That is, all offsets returned by the `RRegex` API are guaranteed
* to be ranges that can slice an `Uint8Array` created with a TextEncoder.
*
* # Example: slicing over UTF strings
*
* ```typescript
* import { RRegex } from "rregex"
*
* const re = new RRegex("ä")
* const m = re.find("äöü") // { start: 0, end: 2 }
*
* const buff = new TextEncoder().encode("äöü")
* const slice = buff.slice(m.start, m.end)
* expect(new TextDecoder().decode(slice)).toBe("ä")
* ```
*
* # Example: find the offsets of a US phone number:
*
* ```typescript
* import { RRegex } from "rregex"
*
* const re = new RRegex("[0-9]{3}-[0-9]{3}-[0-9]{4}");
* const m = re.find("phone: 111-222-3333");
* expect(m.start).toBe(7);
* expect(m.end).toBe(19);
* ```
*
* @see https://docs.rs/regex/latest/regex/
*/
export class RRegex {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RRegexFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rregex_free(ptr);
    }
    /**
    * Compiles a regular expression. Once compiled, it can be used repeatedly
    * to search, split or replace text in a string.
    *
    * If an invalid expression is given, then an error is returned.
    * @param {string} re
    */
    constructor(re) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(re, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.rregex_new(retptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            this.__wbg_ptr = r0 >>> 0;
            return this;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns true if and only if there is a match for the regex in the
    * string given.
    *
    * It is recommended to use this method if all you need to do is test
    * a match, since the underlying matching engine may be able to do less
    * work.
    *
    * # Example
    *
    * Test if some text contains at least one word with exactly 13
    * Unicode word characters:
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const text = "I categorically deny having triskaidekaphobia."
    * expect(new RRegex("\\b\\w{13}\\b").is_match(text)).toBe(true)
    * ```
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.is_match
    * @param {string} text - The string against which to match the regular expression
    * @return {boolean}
    */
    isMatch(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_isMatch(this.__wbg_ptr, ptr0, len0);
        return ret !== 0;
    }
    /**
    * Returns the same as is_match, but starts the search at the given offset.
    * The significance of the starting point is that it takes the surrounding
    * context into consideration. For example, the `\A` anchor can only match
    * when `start == 0`.
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.is_match_at
    * @param {string} text - The string against which to match the regular expression
    * @param {number} start - Zero-based index at which to start matching
    * @return {boolean}
    */
    isMatchAt(text, start) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregex_isMatchAt(this.__wbg_ptr, ptr0, len0, start);
        return ret !== 0;
    }
    /**
    * Returns the start and end byte range of the leftmost-first match in
    * `text`. If no match exists, then `undefined` is returned.
    *
    * Note that this should only be used if you want to discover the position
    * of the match. Testing the existence of a match is faster if you use
    * `isMatch`.
    *
    * # Example
    *
    * Find the start and end location of the first word with exactly 13
    * Unicode word characters:
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const text = "I categorically deny having triskaidekaphobia."
    * const m = new RRegex("\\b\\w{13}\\b").find(text)
    * expect(m.start).toBe(2)
    * expect(m.end).toBe(15)
    * ```
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.find
    * @param {string} text - The string against which to match the regular expression
    * @return {Match}
    */
    find(text) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.rregex_find(retptr, this.__wbg_ptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return takeObject(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns the same as find, but starts the search at the given
    * offset.
    *
    * The significance of the starting point is that it takes the surrounding
    * context into consideration. For example, the `\A` anchor can only
    * match when `start == 0`.
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.find_at
    * @param {string} text - The string against which to match the regular expression
    * @param {number} start - Zero-based index at which to start matching
    * @returns {Match}
    */
    findAt(text, start) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.rregex_findAt(retptr, this.__wbg_ptr, ptr0, len0, start);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return takeObject(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns an array for each successive non-overlapping match in `text``,
    * returning the start and end byte indices with respect to `text`.
    *
    * # Example
    *
    * Find the start and end location of every word with exactly 13 Unicode
    * word characters:
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const text = "Retroactively relinquishing remunerations is reprehensible."
    * const matches = new RRegex("\\b\\w{13}\\b").findAll(text)
    * ```
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.find_iter
    * @param {string} text - The string against which to match the regular expression
    * @returns {Match}
    */
    findAll(text) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.rregex_findAll(retptr, this.__wbg_ptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return takeObject(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns a list of the capture names in this regex.
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.capture_names
    */
    captureNames() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.rregex_captureNames(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns the capture groups corresponding to the leftmost-first
    * match in `text`. Capture group `0` always corresponds to the entire
    * match. If no match is found, then `undefined` is returned.
    *
    * You should only use `captures` if you need access to the location of
    * capturing group matches. Otherwise, `find` is faster for discovering
    * the location of the overall match.
    *
    * # Examples
    *
    * Say you have some text with movie names and their release years,
    * like "'Citizen Kane' (1941)". It'd be nice if we could search for text
    * looking like that, while also extracting the movie name and its release
    * year separately.
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const re = new RRegex("'([^']+)'\\s+\\((\\d{4})\)")
    * const text = "Not my favorite movie: 'Citizen Kane' (1941)."
    * const caps = re.captures(text)
    * expect(caps.get[1].value).toBe("Citizen Kane")
    * expect(caps.get[2].value).toBe("1941")
    * expect(caps.get[0].value).toBe("'Citizen Kane' (1941)")
    * ```
    *
    * Note that the full match is at capture group `0`. Each subsequent
    * capture group is indexed by the order of its opening `(`.
    *
    * We can make this example a bit clearer by using *named* capture groups:
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const re = new RRegex("'(?P<title>[^']+)'\\s+\\((?P<year>\\d{4})\)")
    * const text = "Not my favorite movie: 'Citizen Kane' (1941)."
    * const caps = re.captures(text)
    * expect(caps.name["title"].value).toBe("Citizen Kane")
    * expect(caps.name["year"].value).toBe("1941")
    * expect(caps.get[0].value).toBe("'Citizen Kane' (1941)")
    * ```
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.captures
    * @param {string} text - The string against which to match the regular expression
    * @returns {Captures|undefined}
    */
    captures(text) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.rregex_captures(retptr, this.__wbg_ptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return takeObject(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns a list with all the non-overlapping capture groups matched
    * in `text`. This is operationally the same as `findAll`, except it
    * returns information about capturing group matches.
    *
    * # Example
    *
    * We can use this to find all movie titles and their release years in
    * some text, where the movie is formatted like "'Title' (xxxx)":
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const re = new RRegex("'(?P<title>[^']+)'\\s+\\((?P<year>\\d{4})\\)")
    * const text = "'Citizen Kane' (1941), 'The Wizard of Oz' (1939), 'M' (1931)."
    * for caps of re.captures_iter(text) {
    *     console.log(
    *         "Movie:", caps.name["title"].value, ","
    *         "Released:", caps.name["year"].value
    *     );
    * }
    * // Output:
    * // Movie: Citizen Kane, Released: 1941
    * // Movie: The Wizard of Oz, Released: 1939
    * // Movie: M, Released: 1931
    * ```
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.captures_iter
    * @param {string} text - The string against which to match the regular expression
    * @returns {Captures[]}
    */
    capturesAll(text) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.rregex_capturesAll(retptr, this.__wbg_ptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return takeObject(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns the number of captures.
    *
    * This includes all named and unnamed groups, including the implicit
    * unnamed group that is always present and corresponds to the entire match.
    *
    * Since the implicit unnamed group is always included in this length, the
    * length returned is guaranteed to be greater than zero.
    *
    * ## Example
    *
    * ```typescript
    *  import { RRegex } from "rregex"
    *
    *  const re1 = new RRegex("(?P<y>\\d{4})-(?P<m>\\d{2})-(?P<d>\\d{2})")
    *  expect(re1.capturesLength()).toEqual(4)
    *
    *  const re2 = new RRegex("foo")
    *  expect(re2.capturesLength()).toEqual(1)
    *
    *  const re3 = new RRegex("(foo)")
    *  expect(re3.capturesLength()).toEqual(2)
    *
    *  const re4 = new RRegex("(?<a>.(?<b>.))(.)(?:.)(?<c>.)")
    *  expect(re4.capturesLength()).toEqual(5)
    *
    *  const re5 = new RRegex("[a&&b]")
    *  expect(re5.capturesLength()).toEqual(1)
    * ```
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.captures_len
    * @returns {number}
    */
    capturesLength() {
        const ret = wasm.rregex_capturesLength(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * Replaces the leftmost-first match with the replacement provided.
    * The replacement can be a regular string (where `$N` and `$name` are
    * expanded to match capture groups) or a function that takes the matches'
    * `Captures` and returns the replaced string.
    *
    * If no match is found, then a copy of the string is returned unchanged.
    *
    * # Replacement string syntax
    *
    * All instances of `$name` in the replacement text is replaced with the
    * corresponding capture group `name`.
    *
    * `name` may be an integer corresponding to the index of the
    * capture group (counted by order of opening parenthesis where `0` is the
    * entire match) or it can be a name (consisting of letters, digits or
    * underscores) corresponding to a named capture group.
    *
    * If `name` isn't a valid capture group (whether the name doesn't exist
    * or isn't a valid index), then it is replaced with the empty string.
    *
    * The longest possible name is used. e.g., `$1a` looks up the capture
    * group named `1a` and not the capture group at index `1`. To exert more
    * precise control over the name, use braces, e.g., `${1}a`.
    *
    * To write a literal `$` use `$$`.
    *
    * # Examples
    *
    * Note that this function is polymorphic with respect to the replacement.
    * In typical usage, this can just be a normal string:
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const re = new RRegex("[^01]+")
    * expect(re.replace("1078910", "").toBe("1010")
    * ```
    *
    * Here's the example using this expansion technique with named capture
    * groups:
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const re = new RRegex("(?P<last>[^,\\s]+),\\s+(?P<first>\\S+)")
    * const result = re.replace("Springsteen, Bruce", "$first $last")
    * expect(result).toBe("Bruce Springsteen")
    * ```
    *
    * Note that using `$2` instead of `$first` or `$1` instead of `$last`
    * would produce the same result. To write a literal `$` use `$$`.
    *
    * Sometimes the replacement string requires use of curly braces to
    * delineate a capture group replacement and surrounding literal text.
    * For example, if we wanted to join two words together with an
    * underscore:
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const re = new RRegex("(?P<first>\\w+)\\s+(?P<second>\\w+)")
    * const result = re.replace("deep fried", "${first}_$second")
    * expect(result).toBe("deep_fried")
    * ```
    *
    * Without the curly braces, the capture group name `first_` would be
    * used, and since it doesn't exist, it would be replaced with the empty
    * string.
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.replace
    * @param {string} text - The string against which to match the regular expression
    * @param {string} rep - It's a string, it will replace the substring matched by `pattern`.
    * @returns {string}
    */
    replace(text, rep) {
        let deferred3_0;
        let deferred3_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            const ptr1 = passStringToWasm0(rep, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len1 = WASM_VECTOR_LEN;
            wasm.rregex_replace(retptr, this.__wbg_ptr, ptr0, len0, ptr1, len1);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred3_0 = r0;
            deferred3_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred3_0, deferred3_1, 1);
        }
    }
    /**
    * Replaces at most `limit` non-overlapping matches in `text` with the
    * replacement provided. If `limit` is 0, then all non-overlapping matches
    * are replaced.
    *
    * See the documentation for `replace` for details on how to access
    * capturing group matches in the replacement string.
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.replacen
    * @param {string} text - The string against which to match the regular expression
    * @param {number} limit - Max number of replacement
    * @param {string} rep - It's a string, it will replace the substring matched by `pattern`.
    * @returns {string}
    */
    replacen(text, limit, rep) {
        let deferred3_0;
        let deferred3_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            const ptr1 = passStringToWasm0(rep, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len1 = WASM_VECTOR_LEN;
            wasm.rregex_replacen(retptr, this.__wbg_ptr, ptr0, len0, limit, ptr1, len1);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred3_0 = r0;
            deferred3_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred3_0, deferred3_1, 1);
        }
    }
    /**
    * Replaces all non-overlapping matches in `text` with the replacement
    * provided. This is the same as calling `replacen` with `limit` set to
    * `0`.
    *
    * See the documentation for `replace` for details on how to access
    * capturing group matches in the replacement string.
    *
    * See the documentation for `replace` for details on how to access capturing group matches in the replacement string.
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.replace_all
    * @param {string} text - The string against which to match the regular expression
    * @param {string} rep - It's a string, it will replace the substring matched by `pattern`.
    * @returns {string}
    */
    replaceAll(text, rep) {
        let deferred3_0;
        let deferred3_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            const ptr1 = passStringToWasm0(rep, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len1 = WASM_VECTOR_LEN;
            wasm.rregex_replaceAll(retptr, this.__wbg_ptr, ptr0, len0, ptr1, len1);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred3_0 = r0;
            deferred3_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred3_0, deferred3_1, 1);
        }
    }
    /**
    * Returns an iterator of substrings of `text` delimited by a match of the
    * regular expression. Namely, each element of the iterator corresponds to
    * text that *isn't* matched by the regular expression.
    *
    * This method will *not* copy the text given.
    *
    * # Example
    *
    * To split a string delimited by arbitrary amounts of spaces or tabs:
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const re = new RRegex(r"[ \\t]+")
    * const fields = re.split("a b \t  c\td    e")
    * expect(fields).toEqual(["a", "b", "c", "d", "e"])
    * ```
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.split
    * @param {string} text - The string against which to match the regular expression
    * @returns {string[]}
    */
    split(text) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.rregex_split(retptr, this.__wbg_ptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v2 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v2;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns an iterator of at most `limit` substrings of `text` delimited
    * by a match of the regular expression. (A `limit` of `0` will return no
    * substrings.) Namely, each element of the iterator corresponds to text
    * that *isn't* matched by the regular expression. The remainder of the
    * string that is not split will be the last element in the iterator.
    *
    * This method will *not* copy the text given.
    *
    * # Example
    *
    * Get the first two words in some text:
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const re = new RRegex(r"\\W+")
    * const fields = re.splitn("Hey! How are you?", 3)
    * expect(fields).toEqual(["Hey", "How", "are you?"])
    * ```
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.splitn
    * @param {string} text - The string against which to match the regular expression
    * @param {number} limit - Max number result elements
    * @returns {string[]}
    */
    splitn(text, limit) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.rregex_splitn(retptr, this.__wbg_ptr, ptr0, len0, limit);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v2 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v2;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns the end location of a match in the text given.
    *
    * This method may have the same performance characteristics as
    * `isMatch`, except it provides an end location for a match. In
    * particular, the location returned *may be shorter* than the proper end
    * of the leftmost-first match that you would find via `Regex.find`.
    *
    * Note that it is not guaranteed that this routine finds the shortest or
    * "earliest" possible match. Instead, the main idea of this API is that
    * it returns the offset at the point at which the internal regex engine
    * has determined that a match has occurred. This may vary depending on
    * which internal regex engine is used, and thus, the offset itself may
    * change.
    *
    * # Example
    *
    * Typically, `a+` would match the entire first sequence of `a` in some
    * text, but `shortest_match` can give up as soon as it sees the first
    * `a`.
    *
    * ```typescript
    * import { RRegex } from "rregex"
    *
    * const text = "aaaaa"
    * const = new RRegex("a+").shortest_match(text)
    * expect(pos).toBe(1)
    * ```
    *
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.shortest_match
    * @param {string} text - The string against which to match the regular expression
    * @returns {number|undefined}
    */
    shortestMatch(text) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.rregex_shortestMatch(retptr, this.__wbg_ptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return r0 === 0 ? undefined : r1 >>> 0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns the same as `shortest_match`, but starts the search at the
    * given offset.
    *
    * The significance of the starting point is that it takes the surrounding
    * context into consideration. For example, the `\A` anchor can only match
    * when `start == 0`.
    * @see https://docs.rs/regex/latest/regex/struct.Regex.html#method.shortest_match_at
    * @param {string} text - The string against which to match the regular expression
    * @param {number} start - Zero-based index at which to start matching
    * @returns {number|undefined}
    */
    shortestMatchAt(text, start) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.rregex_shortestMatchAt(retptr, this.__wbg_ptr, ptr0, len0, start);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return r0 === 0 ? undefined : r1 >>> 0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns the regular expression into a high level intermediate
    * representation.
    * @returns {any}
    */
    syntax() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.rregex_syntax(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            return takeObject(r0);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * Returns a string representing the regular expression
    * @returns {string}
    */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.rregex_toString(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            deferred1_0 = r0;
            deferred1_1 = r1;
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const RRegexSetFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rregexset_free(ptr >>> 0));
/**
* Match multiple (possibly overlapping) regular expressions in a single scan.
* A regex set corresponds to the union of two or more regular expressions.
*
* That is, a regex set will match text where at least one of its constituent
* regular expressions matches. A regex set as its formulated here provides a
* touch more power: it will also report which regular expressions in the set match.
* Indeed, this is the key difference between regex sets and a single `Regex`
* with many alternates, since only one alternate can match at a time.
*
* @see https://docs.rs/regex/latest/regex/struct.RegexSet.html#method.empty
*/
export class RRegexSet {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RRegexSetFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rregexset_free(ptr);
    }
    /**
    * Create a new regex set with the given regular expressions.
    *
    * This takes an of strings, if any item in the list is not a valid regular
    * expressions, then an error is returned.
    *
    * # Example
    *
    * Create a new regex set from an iterator of strings:
    *
    * ```typescript
    * import { RegexSet } from "rregex"
    *
    * const set = new RegexSet(["\\w+", "\\d+"])
    * expect(set.is_match("foo")).toBe(true)
    * ```
    * @param {Array<any>} list
    */
    constructor(list) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.rregexset_new(retptr, addBorrowedObject(list));
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var r2 = getInt32Memory0()[retptr / 4 + 2];
            if (r2) {
                throw takeObject(r1);
            }
            this.__wbg_ptr = r0 >>> 0;
            return this;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            heap[stack_pointer++] = undefined;
        }
    }
    /**
    * Returns true if and only if one of the regexes in this set matches
    * the text given.
    *
    * This method should be preferred if you only need to test whether any
    * of the regexes in the set should match, but don't care about *which*
    * regexes matched. This is because the underlying matching engine will
    * quit immediately after seeing the first match instead of continuing to
    * find all matches.
    *
    * Note that as with searches using `Regex`, the expression is unanchored
    * by default. That is, if the regex does not start with `^` or `\A`, or
    * end with `$` or `\z`, then it is permitted to match anywhere in the
    * text.
    *
    * # Example
    *
    * Tests whether a set matches some text:
    *
    * ```typescript
    * import { RegexSet } from "rregex"
    *
    * const set = new RegexSet(["\\w+", "\\d+"])
    * expect(set.is_match("foo")).toBe(true)
    * expect(!set.is_match("☃")).toBe(false)
    * ```
    *
    * @see https://docs.rs/regex/latest/regex/struct.RegexSet.html#method.is_match
    * @param {string} text - The string against which to match the regular expression
    * @return {boolean}
    */
    isMatch(text) {
        const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rregexset_isMatch(this.__wbg_ptr, ptr0, len0);
        return ret !== 0;
    }
    /**
    * Returns the set of regular expressions that match in the given text.
    *
    * The set returned contains the index of each regular expression that
    * matches in the given text. The index is in correspondence with the
    * order of regular expressions given to `RegexSet`'s constructor.
    *
    * The set can also be used to iterate over the matched indices.
    *
    * Note that as with searches using `Regex`, the expression is unanchored
    * by default. That is, if the regex does not start with `^` or `\A`, or
    * end with `$` or `\z`, then it is permitted to match anywhere in the
    * text.
    *
    * # Example
    *
    * Tests which regular expressions match the given text:
    *
    * ```typescript
    * import { RegexSet } from "rregex"
    *
    * const set = new RegexSet([
    *     "\\w+",
    *     "\\d+",
    *     "\\pL+",
    *     "foo",
    *     "bar",
    *     "barfoo",
    *     "foobar",
    * ])
    * const matches = set.matches("foobar")
    * expect(matches).toBe([0, 2, 3, 4, 6])
    * ```
    *
    * @see https://docs.rs/regex/latest/regex/struct.RegexSet.html#method.matches
    * @param {string} text - The string against which to match the regular expression
    * @return {number[]}
    */
    matches(text) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            const ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            wasm.rregexset_matches(retptr, this.__wbg_ptr, ptr0, len0);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v2 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v2;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
}

async function __wbg_load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function __wbg_get_imports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbindgen_number_new = function(arg0) {
        const ret = arg0;
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_object_drop_ref = function(arg0) {
        takeObject(arg0);
    };
    imports.wbg.__wbindgen_string_new = function(arg0, arg1) {
        const ret = getStringFromWasm0(arg0, arg1);
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_string_get = function(arg0, arg1) {
        const obj = getObject(arg1);
        const ret = typeof(obj) === 'string' ? obj : undefined;
        var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len1 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len1;
        getInt32Memory0()[arg0 / 4 + 0] = ptr1;
    };
    imports.wbg.__wbindgen_error_new = function(arg0, arg1) {
        const ret = new Error(getStringFromWasm0(arg0, arg1));
        return addHeapObject(ret);
    };
    imports.wbg.__wbindgen_bigint_from_u64 = function(arg0) {
        const ret = BigInt.asUintN(64, arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_set_f975102236d3c502 = function(arg0, arg1, arg2) {
        getObject(arg0)[takeObject(arg1)] = takeObject(arg2);
    };
    imports.wbg.__wbindgen_object_clone_ref = function(arg0) {
        const ret = getObject(arg0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_get_bd8e338fbd5f5cc8 = function(arg0, arg1) {
        const ret = getObject(arg0)[arg1 >>> 0];
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_length_cd7af8117672b8b8 = function(arg0) {
        const ret = getObject(arg0).length;
        return ret;
    };
    imports.wbg.__wbg_new_16b304a2cfa7ff4a = function() {
        const ret = new Array();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_new_72fb9a18b5ae2624 = function() {
        const ret = new Object();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_set_d4638f722068f043 = function(arg0, arg1, arg2) {
        getObject(arg0)[arg1 >>> 0] = takeObject(arg2);
    };
    imports.wbg.__wbg_from_89e3fc3ba5e6fb48 = function(arg0) {
        const ret = Array.from(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_push_a5b05aedc7234f9f = function(arg0, arg1) {
        const ret = getObject(arg0).push(getObject(arg1));
        return ret;
    };
    imports.wbg.__wbg_fromCodePoint_cedd7612a2ff688f = function() { return handleError(function (arg0) {
        const ret = String.fromCodePoint(arg0 >>> 0);
        return addHeapObject(ret);
    }, arguments) };
    imports.wbg.__wbg_buffer_12d079cc21e14bdb = function(arg0) {
        const ret = getObject(arg0).buffer;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_aa4a17c33a06e5cb = function(arg0, arg1, arg2) {
        const ret = new Uint8Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_new_63b92bc8671ed464 = function(arg0) {
        const ret = new Uint8Array(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_set_1f9b04f170055d33 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = Reflect.set(getObject(arg0), getObject(arg1), getObject(arg2));
        return ret;
    }, arguments) };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };
    imports.wbg.__wbindgen_memory = function() {
        const ret = wasm.memory;
        return addHeapObject(ret);
    };

    return imports;
}

function __wbg_init_memory(imports, maybe_memory) {

}

function __wbg_finalize_init(instance, module) {
    wasm = instance.exports;
    __wbg_init.__wbindgen_wasm_module = module;
    cachedInt32Memory0 = null;
    cachedUint32Memory0 = null;
    cachedUint8Memory0 = null;


    return wasm;
}

function initSync(module) {
    if (wasm !== undefined) return wasm;

    const imports = __wbg_get_imports();

    __wbg_init_memory(imports);

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return __wbg_finalize_init(instance, module);
}

async function __wbg_init(input) {
    if (wasm !== undefined) return wasm;

    if (typeof input === 'undefined') {
        input = new URL('rregex.wasm', import.meta.url);
    }
    const imports = __wbg_get_imports();

    if (typeof input === 'string' || (typeof Request === 'function' && input instanceof Request) || (typeof URL === 'function' && input instanceof URL)) {
        input = fetch(input);
    }

    __wbg_init_memory(imports);

    const { instance, module } = await __wbg_load(await input, imports);

    return __wbg_finalize_init(instance, module);
}

export { initSync }
export default __wbg_init;

/** Build metadata */
export const metadata = {
  "name": "rregex",
  "description": "Rust Regex binding for Javascript",
  "authors": [
    "Fede Ramirez <i@2fd.me>"
  ],
  "homepage": "http://rregex.dev",
  "repository": "https://github.com/2fd/rregex",
  "regex": "1.10.5",
  "regex-syntax": "0.8.4"
}
